import React from 'reactn';
import ApiHelper from './ApiHelper.js';
import { DataStore } from "@aws-amplify/datastore";
import { Video, UserSettings } from "../models";
const api = new ApiHelper();
const tags_list = ['xmas', 'halloween', 'irish', 'summer', 'drinking', 'driving', 'tennessee'];
class Tags extends React.Component{ 

    toggleTag = async(tag) => {
        let tag_values = {};
        if(this.props.video){        
            tags_list.forEach((el) => {
                tag_values[el] = (this.props.video.tags || "").includes(el)
            }) 
        }
        tag_values[tag[0]] = !tag_values[tag[0]]
        console.log(tag_values)
        let add_tag = false;
        let updated_tags = (this.props.video.tags || "" ).replace(tag[0], '') 
        if(tag_values[tag[0]]){
            add_tag = true;
            updated_tags = this.props.video.tags + "," + tag[0] 
        }
        api.tagVideo(this.props.video, tag[0], add_tag)
        await DataStore.save(
            Video.copyOf(this.props.video, updated => {
                updated.tags = updated_tags           
            })
        ); 

        
               
    }
    render(){  
        let tag_values = {};
        if(this.props.video){        
            tags_list.forEach((el) => {
                tag_values[el] = (this.props.video.tags || "").includes(el)
            }) 
        }   
        return (
            <div> 
                { this.global.user && this.global.user.isAdmin ? 
                    <ul className="tags-list">
                        {(Object.entries(tag_values) || []).map((tag) => { return <li key={tag} ><button className={tag_values[tag[0]] ? "active-tag" : ""}onClick={this.toggleTag.bind(this, tag)}>{tag}</button></li>})} 
                    </ul> :
                    <span/>
                } 
            </div>
        )
    }
}
export default Tags;